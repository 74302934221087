import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
	Divider,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, maxWidth } from '@mui/system';
import Loader from 'components/Loadable/Loader';
import RadarChart from 'components/RadarChart';
import dayjs from 'dayjs';
import {
	getSkillRatingTable,
	getSkillRatings,
} from 'global/redux/player/request';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	getPlayerReflection,
	updatePlayerSkills,
} from 'global/redux/player/thunk';

const useStyles = makeStyles(() => ({
	root: {},
	0: {
		backgroundColor: '#FCEBCD',
	},
	1: {
		backgroundColor: '#FBD89B',
	},
	2: {
		backgroundColor: '#CDFCEB',
	},
	3: {
		backgroundColor: '#FCD3CD',
	},
	4: {
		backgroundColor: '#FBA89B',
	},
	5: {
		backgroundColor: '#91e7eb',
	},
	6: {
		backgroundColor: '#c191eb',
	},
	7: {
		backgroundColor: '#e2eb91',
	},
	8: {
		backgroundColor: '#eb9191',
	},
	hover: {
		'&:hover': {
			backgroundColor: '#faeee8',
			transition: 'transform 0.3s ease-in-out',
		},
	},
	tableBorder: {
		borderWidth: 1,
		// borderRightWidth: 1,
		// borderBottomWidth: 1,
		borderColor: 'black',
		borderStyle: 'solid',
		cursor: 'pointer', // Change cursor to pointer
		transition: 'transform 0.3s ease-in-out',
	},
	bgTransparent: {
		backgroundColor: 'transparent',
		border: 'none',
	},
}));

export function SkillRatingTable() {
	const classes = useStyles();
	const params = useParams();
	const dispatch = useDispatch();
	const { eventId, playerId, assessmentId } = params;
	const {
		isLoading,
		selfImprove,
		selfImproveLoading,
		reflection,
		psychology,
		playerSkillRating,
	} = useSelector(({ player }) => ({
		isLoading: player.isLoading,
		selfImprove: player.selfImprove,
		selfImproveLoading: player.selfImproveLoading,
		reflection: player.reflection,
		psychology: player.psychology,
		playerSkillRating: player.playerSkillRating,
	}));
	const { attendendEvents, isLoadingAttendedEvents } = useSelector(
		({ player }) => ({
			attendendEvents: player?.attendendEvents,
			isLoadingAttendedEvents: player?.isLoadingAttendedEvents,
		})
	);
	const { playerInAssessment } = useSelector(({ assessment }) => assessment);
	const { assessment } = useSelector(({ assessment }) => ({
		isLoading: assessment.isLoading,
		assessment: assessment.assessment?.find((item) => item?.id == assessmentId),
	}));
	const playerInfo = playerInAssessment.filter(
		(item) => item?.userID === playerId
	)[0];
	const { userInfo, clubList, totalTeam } = useSelector(({ auth }) => ({
		userInfo: auth.userInfo,
		clubList: auth.clubList,
		totalTeam: auth.totalTeam,
	}));
	const [skillRatingTable, setSkillRatingTable] = useState();
	const [sessionRating, setSessionRating] = useState();
	const currentDate = new Date();
	const [dateRange, setDateRange] = useState({
		start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
		end: new Date(currentDate),
	});
	const [loadingSkillRating, setLoadingSkillRating] = useState(null);

	const playerTable = {
		'IF THE COUNTER ATTACK IS NOT AVAILABLE': {
			'Take up a preventive marking position to stop the opponents counter press':
				'3_100',
			'Push up the defensive line and keep the team connected': '2_66',
			'Keep the ball and allow teammates to take up offensive positions':
				'1_33',
		},
		'DOMINATE 1V1': {
			'Make the tackle when you see the opportunity.': '3_60',
			'Understand when to mark touch tight or zonal.': '4_80',
			'Deny the opponent from turning.': '2_40',
			'Use your body to block or disrupt the movement of the opponent.':
				'5_100',
			'Close down the opponent and force then one way (Away from goal or to a numerical advantage)':
				'1_20',
		},
		'ECOGNIZING THE OPPORTUNITY': {
			'Initiate the attack with a forward pass or dribble. Penetrate with or without the ball.':
				'2_66',
			'Combine passes with teammates.': '1_33',
			'Vocal and positive. Be ready on the weak side to exploit the opponent.':
				'3_100',
		},
		'AWARENESS AND POSITIONING': {
			'Identify angles and distance from your opponent and your teammates.':
				'2_50',
			'Stay connected to the center-back.': '4_100',
			'Recognize the moment to press. Be effective in your area of the field. ANTICIPATE AND PREVENT THREATS':
				'3_75',
			'Analyzes the position of the opponents and their teammates': '1_25',
		},
		'RECOGNIZING THE OPPORTUNITY': {
			'Combine passes with teammates.': '2_66',
			'Be vocal and positive. Be ready on the weak side to exploit the opponent.':
				'1_33',
			'Initiate the attack with a forward pass or dribble. Penetrate with or without the ball.':
				'3_100',
		},
		'ANTICIPATE AND PREVENT THREATS': {
			'Provide cover for center-backs.': '2_100',
			'Create a numerical advantage. Drop and delay when outnumbered.': '1_50',
		},
		'DENYING AND DEFENDING CROSSES, PREVENTING SCORING.': {
			'Deny the cross, prevent the service.': '4_80',
			'Be brave and block shots.': '1_20',
			'Clear the ball with purpose. Can you find a teammate?': '3_60',
			'Do not ball watch. Never lose sight of your direct opponent and the ball.':
				'5_100',
			'Be first to the ball and react quickly to the second ball.': '2_40',
		},
	};

	var playerSkill = {
		'Deny the cross, prevent the service.': '4_80',
		'Be brave and block shots.': '1_20',
		'Clear the ball with purpose. Can you find a teammate?': '3_60',
		'Do not ball watch. Never lose sight of your direct opponent and the ball.':
			'5_100',
		'Be first to the ball and react quickly to the second ball.': '2_40',
	};

	const handleGetSkillRatingTable = async () => {
		await getSkillRatingTable(assessment.teamID)
			.then((res) => {
				setSkillRatingTable(res);
			})
			.catch((err) => {
				console.log(err);
				setSkillRatingTable('error');
			});
	};

	function convertSkillRating(data) {
		const result = [];

		// data?.forEach((session) => {
		// 	const sessionRating = session?.sessionRating;

		// 	Object.values(sessionRating).forEach((category) => {
		// 		if (typeof category == 'object') {
		// 			result.push(category);
		// 		}
		// 	});
		// });

		return data;
	}

	function countSkillRatingCount(name) {
		let count = 0;
		sessionRating?.forEach((obj) => {
			const keys = Object.keys(obj);
			if (keys.length > 0 && keys[0] === name) {
				count++;
			}
		});

		return count > 0 && `(${count})`;
	}

	const getSkillRating = async () => {
		setLoadingSkillRating(true);
		await getSkillRatings(playerId, {
			start: dateRange?.start,
			end: dateRange?.end,
		})
			.then((res) => {
				setSessionRating(convertSkillRating(res));
			})
			.catch((err) => {
				console.log(err);
			});
		setLoadingSkillRating(false);
	};

	function getObjectWithMaxKeys(obj) {
		let maxKeysCount = 0;
		let maxKeysObject = null;

		for (const key in obj) {
			const nestedObject = obj[key];
			const nestedKeysCount = Object.keys(nestedObject).length;
			if (nestedKeysCount > maxKeysCount) {
				maxKeysCount = nestedKeysCount;
				maxKeysObject = nestedObject;
			}
		}
		// console.log('maxKeysCount=====>', maxKeysCount);
		// console.log('maxKeysObject=====>', maxKeysObject);
		return maxKeysObject;
	}
	const handleUpdatePlayerSkill = (skill, subSkillEntry, value) => {
		console.log(skill, subSkillEntry, value);

		const updatedSkill = { ...playerSkillRating[skill] };
		if (updatedSkill[subSkillEntry]) {
			delete updatedSkill[subSkillEntry];
		} else {
			updatedSkill[subSkillEntry] = value;
		}

		// console.log('playerSkillRating', {
		// 	...playerSkillRating,
		// 	[skill]: updatedSkill,
		// });

		// console.log('sessionRating', playerSkillRating);
		// return;
		dispatch(
			updatePlayerSkills({
				skill: {
					...playerSkillRating,
					[skill]: updatedSkill,
				},
				assessmentId: assessmentId,
				playerAssessmentId: playerInfo?.id,
			})
		);

		dispatch(
			getPlayerReflection({
				assessmentId,
				playerAssessmentId: playerInfo?.id,
			})
		);
	};

	useEffect(() => {
		if (!sessionRating) {
			getSkillRating();
		}
	}, [eventId, userInfo]);

	// console.log(
	// 	skillRatingTable && Object.entries(skillRatingTable['1v1 Attacking'])
	// );
	// console.log(getObjectWithMaxKeys(skillRatingTable));
	// console.log(playerSkillRating);
	// console.log(skillRatingTable);

	useEffect(() => {}, [skillRatingTable]);

	useEffect(() => {
		if (assessment) {
			handleGetSkillRatingTable();
		}
	}, [assessment]);

	return (
		<>
			{skillRatingTable ? (
				<>
					{skillRatingTable == 'error' ? (
						<p>Something Went Wrong. Kindly refresh or contact support.</p>
					) : (
						<>
							{Object.keys(skillRatingTable)
								.filter((key) => key.includes('sessionRating'))
								?.map((value, i) => (
									<Box sx={{ p: '0 30px' }}>
										<Divider
											sx={{
												'&::before, &::after': {
													borderColor: 'primary.dark',
												},
											}}
										>
											<Typography variant='h2'>Skills</Typography>
										</Divider>
										<Typography
											variant='subtitle1'
											textAlign={'center'}
											paddingY={2}
										>
											*Click on the table &quot;cell&quot; to update the rating.
										</Typography>
										<div className='dateRangeContainer'>
											{/* <div style={{ maxWidth: '400px' }}> */}
											<RadarChart
												playerSkillRating={playerSkillRating}
												skillRatingTable={skillRatingTable[value]}
												// playerSkillRating={playerSkill}
												// skillRatingTable={playerTable}
											/>
											{/* </div> */}
											<div>
												<div className='dateRangeSelector'>
													<DatePicker
														label='Start Date'
														value={dayjs(new Date(dateRange?.start))}
														onChange={(range) =>
															setDateRange({
																...dateRange,
																start: new Date(range),
															})
														}
													/>
													<DatePicker
														label='End Date'
														value={dayjs(new Date(dateRange?.end))}
														onChange={(range) =>
															setDateRange({
																...dateRange,
																end: new Date(range),
															})
														}
													/>
												</div>
												<LoadingButton
													sx={{ marginTop: '10px' }}
													fullWidth
													onClick={() => getSkillRating()}
													loading={loadingSkillRating}
													size='large'
													variant='outlined'
												>
													Select
												</LoadingButton>
											</div>
										</div>
										<Stack direction={'column-reverse'}>
											<Stack
												direction='row'
												justifyContent='space-between'
												sx={{ p: 3 }}
											>
												<Box
													sx={{
														width: '100%',
													}}
												>
													<TableContainer
														component={Paper}
														sx={{ borderRadius: 0 }}
													>
														<Table
															sx={{
																tableLayout: 'auto',
																borderCollapse: 'collapse',
																border: '1px solid black',
																borderBottom: '1.5px solid black',
															}}
														>
															<TableHead>
																<TableRow>
																	<TableCell
																		align='center'
																		className={classes.tableBorder}
																	>
																		TOPIC
																	</TableCell>
																	{Object.keys(
																		getObjectWithMaxKeys(
																			skillRatingTable[value]
																		)
																	)?.map((_, i) => (
																		<TableCell
																			key={`header-${i}`}
																			align='center'
																			className={classes.tableBorder}
																		>
																			Level {i + 1}
																		</TableCell>
																	))}
																</TableRow>
															</TableHead>
															<TableBody>
																{Object.keys(skillRatingTable[value])
																	?.sort()
																	?.map((skill, skillIndex) => (
																		<TableRow key={`row-${skillIndex}`}>
																			{/* Skill Name */}
																			<TableCell
																				align='center'
																				className={classes.tableBorder}
																			>
																				<b>{skill}</b>
																			</TableCell>
																			{/* Skill Levels */}
																			{Object.keys(
																				getObjectWithMaxKeys(
																					skillRatingTable[value]
																				)
																			)?.map((_, levelIndex) => {
																				// Find subSkill matching the current level
																				const subSkillEntry = Object.entries(
																					skillRatingTable[value][skill]
																				)
																					?.sort((a, b) => {
																						const aPosition =
																							parseInt(a[1]?.split('_')[0]) ||
																							Infinity;
																						const bPosition =
																							parseInt(b[1]?.split('_')[0]) ||
																							Infinity;

																						// If aPosition or bPosition is null or NaN, sort based on subSkillValue instead
																						if (
																							aPosition === Infinity ||
																							bPosition === Infinity
																						) {
																							const aValue =
																								a[1]?.split('_')[1] || '';
																							const bValue =
																								b[1]?.split('_')[1] || '';
																							return aValue.localeCompare(
																								bValue
																							);
																						}

																						return aPosition - bPosition;
																					})
																					?.find(
																						([_, value]) =>
																							parseInt(value?.split('_')[0]) ===
																							levelIndex + 1
																					);

																				if (subSkillEntry) {
																					const [subSkillKey, subSkillValue] =
																						subSkillEntry;

																					return (
																						<TableCell
																							key={`cell-${skillIndex}-${levelIndex}`}
																							align='center'
																							className={`${
																								classes.tableBorder
																							} ${classes.hover} ${
																								playerSkillRating[skill]?.[
																									subSkillKey
																								] === subSkillValue &&
																								classes[skillIndex]
																							}`}
																							onClick={() => {
																								handleUpdatePlayerSkill(
																									skill,
																									subSkillKey,
																									subSkillValue
																								);
																							}}
																						>
																							{subSkillKey}
																							<div className='skillRatingCount'>
																								{countSkillRatingCount(
																									subSkillKey
																								)}
																							</div>
																						</TableCell>
																					);
																				}

																				// Check if subSkillValue is empty, null, or not in the "level_number_value" format
																				const subSkillValue = subSkillEntry
																					? subSkillEntry[1]
																					: '';
																				const isInvalidValue =
																					!subSkillValue ||
																					!subSkillValue.includes('_');

																				return (
																					<TableCell
																						key={`empty-cell-${skillIndex}-${levelIndex}`}
																						align='center'
																						className={`${
																							classes.tableBorder
																						} ${
																							isInvalidValue ? 'fade-in' : ''
																						}`}
																					>
																						{/* Empty space */}
																					</TableCell>
																				);
																			})}
																		</TableRow>
																	))}
															</TableBody>
														</Table>
													</TableContainer>
													{/*eslint-enable*/}
												</Box>
											</Stack>
										</Stack>
									</Box>
								))}
						</>
					)}
				</>
			) : (
				<Loader />
			)}
		</>
	);
}
